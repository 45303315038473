import { getPolicyIcon } from '@/components/attendance/admin/wizard/useLeavePoliciesWizard';
import { ConfirmDialog } from '@/components/basic/ConfirmDialog/ConfirmDialog';
import { CalendarXMark } from '@/components/basic/icons/CalendarXMark';
import { DayPill } from '@/components/dashboard/DayPill';
import {
  WorkingLocationSelector,
  getLocationIdentifier
} from '@/components/dashboard/WorkingLocationSelector';
import { IAttendanceRequestTypeWithTitle } from '@/hooks/useAttendanceRequests';
import { api } from '@/lib/api/api';
import { classNames } from '@/lib/classNames';
import {
  dateParseISO,
  getMaxDateAllowedToReserve,
  parseApiDate
} from '@/lib/utils';
import {
  IOfficesSchedulesIndexed,
  IResource,
  IWorkingLocation,
  IWorkingLocationPresenceType,
  IWorkingLocations,
  WL_NOT_AVAILABLE,
  WL_PRESENCE_OFFICE,
  WeekDayNames,
  isParking,
  parseDateOnlyString
} from '@gettactic/api';
import { Office } from '@gettactic/api';
import { useAuthenticated } from '@gettactic/hooks';
import { HomeIcon, OfficeBuildingIcon } from '@heroicons/react/outline';
import { DesktopComputerIcon, PlusCircleIcon } from '@heroicons/react/solid';
import { useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { startOfDay } from 'date-fns';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';
import { BsCircleFill } from 'react-icons/bs';

type Props = {
  scheduleMeData: IOfficesSchedulesIndexed;
  workingLocationData: IWorkingLocations;
  attendanceByDay: {
    [idx: string]: IAttendanceRequestTypeWithTitle;
  };
  updateWorkingLocation: (
    workingLocationId: IWorkingLocationPresenceType,
    date: string,
    dayNumber: number,
    currentLocation: IWorkingLocation | null
  ) => Promise<void>;
  openReserveModal: (date: string, office: Office | undefined) => void;
};

// If office is closed Saturday & Sunday, this should be 5. This is _only_ for default office hours, not one-off holidays. On
// holidays we want to show the "Office Closed" message.

export function WorkingLocationDatePills({
  scheduleMeData,
  workingLocationData,
  attendanceByDay,
  updateWorkingLocation,
  openReserveModal
}: Props) {
  const router = useRouter();
  const queryClient = useQueryClient();
  const [onConfirmDialogCallback, setOnConfirmDialogCallback] = useState<
    (() => void) | null
  >(null);
  const today = format(new Date(), 'yyyy-MM-dd');
  const {
    userContext: { authenticatedUser }
  } = useAuthenticated();
  const [flippedDate, setFlippedDate] = useState<boolean>(
    authenticatedUser.organization?.features?.includes('show.meta.info') ??
      false
  );
  const findWL = useCallback(
    (day: WeekDayNames) =>
      workingLocationData.days[day] &&
      workingLocationData.days[day].find(
        (x) => x.user.id === authenticatedUser.user?.id
      ),
    [workingLocationData, authenticatedUser.user?.id]
  );
  const locations = useMemo(
    () =>
      Object.keys(workingLocationData.days).map((x, idx) => {
        if (attendanceByDay[x as WeekDayNames]) {
          return attendanceByDay[x as WeekDayNames] ?? null;
        }
        const wl = findWL(x as WeekDayNames);
        if (wl) {
          return wl;
        }
        return null;
      }),
    [workingLocationData, findWL, attendanceByDay]
  );
  const usageDaysToShow = useMemo(
    () =>
      Object.keys(scheduleMeData.days).filter((usage, index) => {
        const loc = getLocationIdentifier(locations[index]);
        return loc !== WL_NOT_AVAILABLE;
      }),
    [scheduleMeData.days, locations]
  );
  const officeDefaultOpenDays = usageDaysToShow.length;

  const goToMap = (resource: IResource, selectedDate: string) => {
    // TODO: Probably we should forward schedule hours,
    //  but we don't have direct access to the schedule.id
    router.push(
      `/map#resource=${resource?.id}&office=${resource?.office_id}&date=${selectedDate}`
    );
  };

  return (
    <ul
      role="list"
      className={classNames(
        // don't remove the false: we force tailwind parser to include all of these classes
        false &&
          'md:grid-cols-7 md:grid-cols-6 md:grid-cols-5 md:grid-cols-4 md:grid-cols-3 md:grid-cols-2 md:grid-cols-1',
        ` grid w-full grid-cols-4 gap-4 md:grid-cols-${officeDefaultOpenDays}`
      )}
    >
      {Object.keys(scheduleMeData.days).map((day, index) => {
        const fullLoc = locations[index];
        const startDate = parseApiDate(day);
        const loc = getLocationIdentifier(locations[index]);
        const dontAllowChangeWorkingLocation = day < today;

        // Override icon to show generic OOO icon for anything that is not office or remote
        let OOOIcon =
          fullLoc && 'policy_type' in fullLoc && !!fullLoc.policy_type
            ? getPolicyIcon(fullLoc.policy_type)
            : null;
        if (fullLoc?.policy_type === 'office') {
          OOOIcon = OfficeBuildingIcon;
        } else if (fullLoc?.policy_type === 'remote') {
          OOOIcon = HomeIcon;
        } else {
          OOOIcon = CalendarXMark;
        }

        if (loc === WL_NOT_AVAILABLE) {
          return null;
        }

        // @ts-ignore
        const office = fullLoc?.office;
        const officeTimeZone = office?.timezone ?? 'UTC';
        const startOfCurrentDay = startOfDay(new Date());
        const maxAllowedReserveDate = getMaxDateAllowedToReserve(
          officeTimeZone,
          office,
          'desk'
        );
        const usageDate = new Date(day);
        return (
          <li
            key={`datePill_${day}`}
            className="col-span-2 flex flex-col gap-2 md:col-span-1"
          >
            <DayPill
              fullLocation={fullLoc}
              flipped={flippedDate}
              setFlipped={setFlippedDate}
              day={day}
            />
            {!fullLoc || 'location' in fullLoc ? (
              <>
                <WorkingLocationSelector
                  date={day}
                  readonly={dontAllowChangeWorkingLocation}
                  type="dashboard"
                  setValue={(val) => {
                    updateWorkingLocation(
                      val as IWorkingLocationPresenceType,
                      day,
                      index,
                      fullLoc
                    );
                  }}
                  value={getLocationIdentifier(locations[index])}
                  fullLocation={fullLoc}
                />
                {!!fullLoc?.resource && !isParking(fullLoc?.resource) ? (
                  <button
                    disabled={dontAllowChangeWorkingLocation}
                    title={fullLoc?.resource?.name}
                    onClick={() => goToMap(fullLoc?.resource!, day)}
                    className={`inline-flex items-center justify-center rounded-full border \
                    border-transparent bg-white px-2 py-2 text-base font-medium \
                    drop-shadow-[0_1px_3px_rgba(0,0,0,0.25)] lg:px-5 \
                    ${dontAllowChangeWorkingLocation ? 'text-gray-400' : ''}`}
                  >
                    <DesktopComputerIcon className="mr-2 h-5 w-5" />
                    <span className="truncate">{fullLoc?.resource?.name}</span>
                  </button>
                ) : fullLoc?.location === WL_PRESENCE_OFFICE &&
                  fullLoc?.office &&
                  usageDate <= maxAllowedReserveDate! &&
                  usageDate >= startOfCurrentDay ? (
                  <>
                    <button
                      type="button"
                      onClick={() => openReserveModal(day, fullLoc?.office)}
                      className="inline-flex items-center justify-center \
                      rounded-full border border-transparent bg-white px-2 py-2 \
                      text-base font-medium text-primary \
                      drop-shadow-[0_1px_3px_rgba(0,0,0,0.25)] \
                      focus:outline-none focus:ring-2 focus:ring-primary \
                      focus:ring-offset-2 lg:px-5"
                    >
                      <PlusCircleIcon className="mr-2 h-5 w-5 text-primary" />
                      Workspace
                    </button>
                  </>
                ) : null}
              </>
            ) : (
              <>
                {/* is a IAttendance */}
                <button
                  type="button"
                  onClick={() => {
                    const now = new Date();
                    now.setHours(0, 0, 0, 0);
                    if (dateParseISO(fullLoc.starts) > now) {
                      setOnConfirmDialogCallback(() => async () => {
                        try {
                          await api.client.leave.deleteRequests([fullLoc.id]);
                          await queryClient.invalidateQueries(['schedules']);
                          await queryClient.invalidateQueries([
                            'attendanceRequests'
                          ]);
                          await queryClient.invalidateQueries([
                            'leavePolicies'
                          ]);
                        } catch (e) {
                          console.error(
                            'Cancelling attendance request failed',
                            e
                          );
                          toast.error(
                            "Sorry we couldn't cancel the Attendance Request"
                          );
                        }
                      });
                    } else {
                      router.push({
                        pathname: `/settings/attendance`,
                        query: {
                          start: format(
                            parseDateOnlyString(fullLoc.starts),
                            'yyyy-MM-dd'
                          )
                        }
                      });
                    }
                  }}
                  className={classNames(
                    'inline-flex items-center justify-between overflow-hidden rounded-full border border-transparent bg-white px-2 py-2 text-base font-medium drop-shadow-[0_1px_3px_rgba(0,0,0,0.25)]  focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 lg:px-5'
                  )}
                >
                  <span className="mr-2 inline-flex items-center text-secondary">
                    {OOOIcon ? <OOOIcon className="mr-2 h-5 w-5" /> : null}
                    <span className="truncate text-ellipsis whitespace-nowrap capitalize ">
                      {fullLoc.title ? fullLoc.title : fullLoc.policy_type}
                    </span>
                  </span>
                  {fullLoc.status === 'pending' ? (
                    <span
                      title="Pending Approval"
                      className="inline-flex items-center"
                    >
                      <BsCircleFill
                        className="mt-0.5 mr-1 h-3 w-3 text-yellow-500"
                        aria-hidden="true"
                      />
                    </span>
                  ) : (
                    <span className="inline-flex items-center">
                      <BsCircleFill
                        className="mt-0.5 mr-1 h-3 w-3 text-green-500"
                        aria-hidden="true"
                      />
                    </span>
                  )}
                </button>
              </>
            )}
          </li>
        );
      })}

      {onConfirmDialogCallback ? (
        <ConfirmDialog
          type="confirm"
          onConfirm={onConfirmDialogCallback}
          title="Do you want to cancel this request?"
          onClose={() => setOnConfirmDialogCallback(null)}
          open={!!onConfirmDialogCallback}
        >
          <p className="text-sm text-gray-500">
            <span className="">
              If you confirm, the request will be cancelled
            </span>
            .<br /> See all your requests by visiting{' '}
            <Link href={'/settings/attendance'}>
              <a className="text-primary-text">My Attendance</a>
            </Link>
            .
          </p>
        </ConfirmDialog>
      ) : null}
    </ul>
  );
}
