import { DatePicker } from '@/components/basic';
import { useI18n } from '@/hooks/useI18n';
import { classNames } from '@/lib/classNames';
import { useAuthenticated } from '@gettactic/hooks';
import { CalendarIcon } from '@heroicons/react/outline';
import { differenceInDays, format, max, min } from 'date-fns';

type Props = {
  start: Date;
  end: Date | null;
  onChange: (start: Date, end: Date | null) => void;
};
export function OOODatePicker({ start, end, onChange }: Props) {
  const {
    userContext: { authenticatedUser }
  } = useAuthenticated();
  const { templates } = useI18n(authenticatedUser);
  const diff =
    differenceInDays(max([start, end ?? start]), min([start, end ?? start])) +
    1;
  return (
    <div>
      <DatePicker
        selected={start}
        //@ts-expect-error - this is a valid prop
        onChange={(dates: [Date, Date | null] | null) => {
          if (!dates) {
            return;
          }
          const [start, end] = dates;
          onChange(start, end);
        }}
        startDate={start}
        endDate={end}
        //@ts-expect-error - this is a valid prop
        selectsRange={true}
        customInput={
          <span className="inline-flex items-center rounded-full border border-gray-300 bg-white p-2 text-gray-800 ">
            <CalendarIcon className="h-6 w-6" />
            <span className="ml-4">
              {format(start, templates.displayDate)} -{' '}
              {format(end ?? start, templates.displayDate)}
            </span>
            <span
              className={classNames(
                'ml-2 rounded-full bg-gray-100 px-2.5  py-0.5 font-medium text-gray-800 '
              )}
            >
              {diff} {diff === 1 ? 'day' : 'days'}
            </span>
          </span>
        }
        className="input w-full rounded-md"
        calendarClassName="tailwind-datepicker"
      />
    </div>
  );
}
