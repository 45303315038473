import { api } from '@/lib/api/api';
import { ApiClientError, CalendarSource } from '@gettactic/api';
import * as Sentry from '@sentry/nextjs';
import { useQuery } from '@tanstack/react-query';

export function useAuthorizedIntegrations() {
  const {
    data: authorizedIntegrations,
    error,
    isLoading
  } = useQuery(
    ['calendar.getAuthorizedIntegrations'],
    async () => {
      const [isGoogleAuthorized, isMicrosoftAuthorized] = await Promise.all([
        api.client.calendar.hasAuthorization(CalendarSource.Google),
        api.client.calendar.hasAuthorization(CalendarSource.Microsoft)
      ]);

      return { isGoogleAuthorized, isMicrosoftAuthorized };
    },
    {
      onError: (error: unknown) => {
        if (error instanceof ApiClientError && error.response?.status === 403) {
          // we can ignore this 403 error
          console.error('getAuthorizedIntegrations 403', error);
          return;
        }
        Sentry.captureException(error, {
          tags: {
            category: 'api-client'
          }
        });
      },
      meta: {
        shouldIgnoreGlobalErrorHandler: true
      }
    }
  );
  const loadingAuthorizations = isLoading;
  return { authorizedIntegrations, loadingAuthorizations, error };
}

export function useAuthorizationUrl(
  source: CalendarSource,
  organizationId: string
) {
  return api.client.calendar.getAuthorizationUrl(source, organizationId);
}

export function useCalendarByResource(resourceId: string) {
  const {
    data: calendar,
    error,
    isLoading
  } = useQuery(
    ['integrations.calendar', resourceId],
    async () => {
      const res = await api.client.calendar.getCalendarByResourceId(
        resourceId as string
      );
      return res.result;
    },
    {
      onError: (error) => {
        return {};
      },
      meta: {
        shouldIgnoreGlobalErrorHandler: true
      }
    }
  );
  return { calendar, isLoading, error };
}
