import { api } from '@/lib/api/api';
import { isReservationSurveyEnabled } from '@/lib/featureFlags';
import { useAuthenticated } from '@gettactic/hooks';
import { useQuery } from '@tanstack/react-query';
import { IoArrowForward } from 'react-icons/io5';

export function DashboardQuestionnaireAlert() {
  const {
    userContext: { currentOffice, authenticatedUser }
  } = useAuthenticated();
  const reservationSurvey = isReservationSurveyEnabled(authenticatedUser);
  const questionnaireData = useQuery(
    ['organizations.questionnaire', currentOffice.id],
    async () => {
      const res = await api.client.organizations.questionnaire(
        currentOffice.id
      );
      return res.result;
    },
    {
      keepPreviousData: true,
      enabled: !!currentOffice.id
    }
  );
  return questionnaireData.data?.is_mandatory &&
    questionnaireData.data.can_respond &&
    !questionnaireData.data.is_passed ? (
    <a href="/questionnaire">
      <div className="flex items-center text-red-500">
        <div className="mt-0.5 mr-1 shrink-0">
          <svg
            className="mt-0.5 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <h3 className="mr-1 underline">
          {reservationSurvey ? 'Survey' : 'Questionnaire'} required
        </h3>
        <IoArrowForward className="inline h-4 w-4" />
      </div>
    </a>
  ) : null;
}
