import { hasVaccineDocument } from '@/components/health/health-helpers';
import { api } from '@/lib/api/api';
import { IDocumentPartial, ResGetHealthDocuments } from '@gettactic/api';
import { useAuthenticated } from '@gettactic/hooks';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';

export function useVaccinationDocument(id: string | null) {
  const { data: document, error } = useQuery(
    ['health.getVaccinationDocument', id],
    async () => {
      const res = await api.client.health.getVaccinationDocument(id as string);
      return res.result;
    },
    { enabled: id !== null }
  );
  return { document, error };
}

export function useTestDocument(id: string | null) {
  const { data: document, error } = useQuery(
    ['health.getTestDocument', id],
    async () => {
      const res = await api.client.health.getTestDocument(id as string);
      return res.result;
    },
    {
      enabled: id !== null
    }
  );
  return { document, error };
}

export function useDocumentImage(doc: IDocumentPartial) {
  const { document } = useConcreteDocument(doc);
  return {
    contents: document ? document.contents : null
  };
}

export function useHasPendingDocuments() {
  const options = {
    status: 'pending'
  };
  //@ts-expect-error Status not string
  const { data } = useDocumentsPaginated(options, 1);
  const len = data && data.pages[0] ? data.pages[0].elements.length : 0;
  return { hasPending: len > 0 };
}

export function useConcreteDocument(doc: IDocumentPartial) {
  const { document: vaccDocument } = useVaccinationDocument(
    doc && hasVaccineDocument(doc) ? doc.id : null
  );
  const { document: testDocument } = useTestDocument(
    doc && !hasVaccineDocument(doc) ? doc.id : null
  );
  return {
    document: vaccDocument ? vaccDocument : testDocument ? testDocument : null
  };
}

export function useDocumentsCurrentUser() {
  const {
    userContext: { authenticatedUser }
  } = useAuthenticated();
  const options = authenticatedUser?.user?.id
    ? { users: [authenticatedUser?.user?.id] }
    : null;
  return useDocumentsPaginated(options);
}

export default function useDocumentsPaginated(
  options: {
    search?: string | null;
    status?: 'accepted' | 'pending' | 'rejected' | null;
    type?: 'test' | 'vaccination' | null;
    offices?: string[] | null;
    users?: string[] | undefined;
  } | null,
  limit = 10
) {
  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status
  } = useInfiniteQuery(
    ['health.documents', options, limit],
    async ({ pageParam: cursor }) => {
      const res = await api.client.health.documents({
        ...options,
        ...{ cursor, limit }
      });
      return res.result;
    },
    {
      getNextPageParam: (lastPage) =>
        (lastPage?.elements.length ?? 0) < limit
          ? undefined
          : (lastPage?.cursor ?? undefined)
    }
  );
  return {
    data,
    error,
    hasNextPage,
    isFetching,
    status,
    fetchNextPage,
    isFetchingNextPage
  };
}
