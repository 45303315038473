import { Button, TextEditorContent } from '@/components/basic';
import { Tooltip } from '@/components/basic';
import { ReservationCardRespondPresence } from '@/components/partial/Cards/ReservationCard/ReservationCardRespondPresence';
import {
  USER_PHOTO_SIZES,
  UserPhotoCard
} from '@/components/partial/Cards/UserPhotoCard';
import { ReservationCancel } from '@/components/reserve/ReservationCancel/ReservationCancel';
import ReserveCheckInButton from '@/components/reserve/ReserveCheckin/ReserveCheckInButton';
import ReserveEditModal from '@/components/reserve/ReserveEditModal';
import { useResourceParents } from '@/hooks/useResourceParents';
import { api } from '@/lib/api/api';
import { canScheduleOthers } from '@/lib/permissions';
import { formatStartEndHours, scheduleResourceStyling } from '@/lib/utils';
import { getDistanceToNow } from '@/lib/utils';
import { goToMap } from '@/lib/utils-map';
import { findCheckinTargetSlot } from '@/lib/utils-reserve';
import { IReservation } from '@/types/Reservation';
import {
  ISchedule,
  ISlot,
  PRESENCE_STATUS_ACCEPTED,
  PRESENCE_STATUS_PENDING,
  PRESENCE_STATUS_REJECTED,
  PRESENCE_TYPE_IN_PERSON,
  PRESENCE_TYPE_REMOTE,
  isParking
} from '@gettactic/api';
import { IResource } from '@gettactic/api';
import { useAuthenticated } from '@gettactic/hooks';
import {
  BanIcon,
  CheckIcon,
  DocumentTextIcon,
  MapIcon as Map,
  OfficeBuildingIcon,
  PencilIcon as Pencil,
  PlusIcon,
  QuestionMarkCircleIcon,
  RefreshIcon as Repeat,
  TrashIcon,
  VideoCameraIcon
} from '@heroicons/react/outline';
import { Menu } from '@mantine/core';
import { ScrollArea } from '@mantine/core';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { format, isPast, isToday, parseISO } from 'date-fns';
import { useRouter } from 'next/router';
import React, { useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';

function duplicatesExist(arr: ISlot[]) {
  const slotUserIds = arr.map((obj) => obj.user.id);
  return new Set(slotUserIds).size !== arr.length;
}

// Temporary function that allows us to remove duplicate user slots
// from meeting room schedules. This is a temporary fix that only needs to exist
// until the API is updated to not return duplicates.
function removeDuplicateSlots(slots: ISlot[]): ISlot[] {
  const uniqueSlots: { [key: string]: ISlot } = {};

  slots.forEach((slot) => {
    if (!uniqueSlots[slot.user.id]) {
      uniqueSlots[slot.user.id] = slot;
    }
  });

  return Object.values(uniqueSlots);
}

function isRoom(type: string): boolean {
  return type === 'meeting_room';
}

function isWorkspace(type: string): boolean {
  return type === 'desk' || type === 'workspace';
}

interface RBReservationCardItemProps {
  schedule: ISchedule;
  embedded?: boolean;
  setOpenReservationsPanel?: React.Dispatch<React.SetStateAction<boolean>>;
}

const RBReservationCardItem: React.FC<RBReservationCardItemProps> = ({
  schedule,
  embedded = false,
  setOpenReservationsPanel
}) => {
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    userContext: { authenticatedUser, currentOffice }
  } = useAuthenticated();
  const router = useRouter();
  const endDate = new Date(schedule.end.replace('Z', ''));
  const scheduleInThePast = isPast(endDate);
  const targetSlot = findCheckinTargetSlot(
    schedule,
    authenticatedUser.user?.id ?? ''
  );
  // If multiple slots on single schedule, prioritize desk/workspace over parking spaces for display
  const prioritySlot = schedule.slots.sort((s) =>
    s.resource.type === 'desk' || s.resource.type === 'workspace' ? -1 : 1
  )[0];
  const resourceChainQuery = useResourceParents(prioritySlot.resource);
  const parking = schedule.slots.find((x) => isParking(x.resource));
  const scheduleStyle = scheduleResourceStyling(prioritySlot.resource.type);
  const userSlot: ISlot | undefined = schedule.slots.find(
    (s) => s.user.id === authenticatedUser.user?.id
  );
  const userMultipleResources = duplicatesExist(schedule.slots);
  const queryClient = useQueryClient();
  const renderCheckIn = useRef(false);

  // If the remote_checkin setting is false, we need to hide the check-in / check-out buttons
  // TODO: We will need to decide what to do with this logic as we add additional resource types
  const office = authenticatedUser?.offices?.byId[currentOffice.id];
  const officeHasRoomEnableCheckIn = office?.meeting_room_remote_checkin;
  const officeHasWorkspaceEnableCheckIn = office?.workspace_remote_checkin;

  const pendingApproval: boolean = schedule.is_pending;
  const earliestCheckInTime: string =
    schedule.slots[0]?.earliest_start_check_in_time ?? '';
  const endTime: string = schedule.slots[0]?.end;
  const scheduleId: string = schedule.id;
  const resourceId: string = schedule.resource.id;

  const checkinMutation = useMutation(
    async ({
      resourceId,
      scheduleId,
      slotId
    }: { resourceId: string; scheduleId: string; slotId: string }) => {
      setLoading(true);
      const res = await api.client.schedules.checkin(
        resourceId,
        scheduleId,
        slotId
      );
      queryClient.invalidateQueries(['schedules']);
    },
    {
      onSuccess: (result) => {
        setLoading(false);
        toast.success("You're checked in");
      },
      onError: (result) => {
        setLoading(false);
        setLoading(false);
        toast.error(`Sorry we couldn't complete the check in`);
      }
    }
  );
  const checkoutMutation = useMutation(
    async ({
      resourceId,
      scheduleId,
      slotId
    }: { resourceId: string; scheduleId: string; slotId: string }) => {
      setLoading(true);
      const res = await api.client.schedules.checkout(
        resourceId,
        scheduleId,
        slotId
      );
      queryClient.invalidateQueries(['schedules']);
    },
    {
      onSuccess: (result) => {
        setLoading(false);
        toast.success("You've checked out");
      },
      onError: (result) => {
        setLoading(false);
        toast.error(`Sorry we couldn't complete the checkout`);
      }
    }
  );

  const onCheckin = async (
    resourceId: string,
    scheduleId: string,
    slotId: string
  ) => {
    checkinMutation.mutate({ resourceId, scheduleId, slotId });
  };
  const onCheckout = async (
    resourceId: string,
    scheduleId: string,
    slotId: string
  ) => {
    checkoutMutation.mutate({ resourceId, scheduleId, slotId });
  };
  const dateFormatted = format(
    parseISO(schedule.start.replace('Z', '')),
    'eeee, d MMM'
  );

  // See note on removeDuplicateSlots() function. This is temporary.
  // Once need is gone, we can move back to schedule.slots.map()
  const uniqueMeetingRoomSlots = removeDuplicateSlots(schedule.slots);

  const resourceType = prioritySlot.resource.type;
  const remoteCheckInDisabled: boolean = useMemo(() => {
    return (
      (isWorkspace(resourceType) && !officeHasWorkspaceEnableCheckIn) ||
      (isRoom(resourceType) && !officeHasRoomEnableCheckIn)
    );
  }, [
    officeHasWorkspaceEnableCheckIn,
    officeHasRoomEnableCheckIn,
    resourceType
  ]);

  const reservationObject: IReservation = useMemo(() => {
    if (targetSlot) {
      const allowCheckIn =
        !!targetSlot?.capabilities?.can_respond_presence_check_in;
      const allowCheckOut =
        !!targetSlot?.capabilities?.can_respond_presence_check_out;
      const canRespondCancel =
        !!targetSlot?.capabilities?.can_respond_cancelled;
      //@ts-ignore
      const hasCheckedIn = !!targetSlot?.has_checked_in;
      //@ts-ignore
      const hasCheckedOut = !!targetSlot?.has_checked_out;
      const checkInTime = !!targetSlot?.is_check_in_time;
      const checkOutTime = targetSlot?.check_out_time;
      const checkInCheckOutDisabled: boolean = !allowCheckIn && !allowCheckOut;
      const canRespondPresence =
        !!targetSlot?.capabilities?.can_respond_presence;
      const canRespondPresenceRemote =
        !!targetSlot?.capabilities?.can_respond_presence_remote;
      const canRespondPresenceInPerson =
        !!targetSlot?.capabilities?.can_respond_presence_in_person;
      renderCheckIn.current = !checkInCheckOutDisabled;
      const canEdit = targetSlot?.capabilities?.can_edit;
      return {
        slotId: targetSlot?.id,
        allowCheckIn,
        allowCheckOut,
        canRespondCancel,
        hasCheckedIn,
        hasCheckedOut,
        checkInTime,
        checkInCheckOutDisabled,
        checkOutTime,
        canRespondPresence,
        canRespondPresenceRemote,
        canRespondPresenceInPerson,
        canEdit
      };
    }
    return {};
  }, [targetSlot]);

  const currentUserCanEdit =
    reservationObject?.canEdit &&
    !scheduleInThePast &&
    (schedule.organizer_id === authenticatedUser?.user?.id ||
      canScheduleOthers(authenticatedUser) ||
      (prioritySlot.resource.type !== 'meeting_room' &&
        reservationObject?.canRespondCancel));

  return (
    <div className="px-1">
      <li
        key={schedule.id}
        className="grid min-h-[275px] grid-cols-12 mb-2 rounded-lg \
        bg-white drop-shadow-[0_1px_3px_rgba(0,0,0,0.25)] \
        lg:mr-2 lg:min-h-[175px] px-1 py-5 lg:px-0 lg:py-0"
      >
        <div
          className={`hidden ${!reservationObject?.canRespondPresence && reservationObject?.checkInCheckOutDisabled && 'grayscale'} lg:flex ${scheduleStyle?.style} relative col-span-3 h-full w-full items-center justify-center overflow-hidden rounded-l-lg lg:col-span-2`}
        >
          <img
            src={scheduleStyle?.image}
            alt={scheduleStyle?.alt}
            className="h-[175px] object-cover"
          />
        </div>
        <div className="col-span-12 rounded-r-lg lg:col-span-10">
          <div className="flex flex-col ml-2 px-3 py-1.5 gap-2 justify-between h-full">
            <div className="flex flex-row flex-1 grid grid-cols-12 gap-4">
              <div
                className={`${scheduleStyle?.style} ${!reservationObject?.canRespondPresence && reservationObject?.checkInCheckOutDisabled && 'grayscale'} col-span-3 flex h-full w-full items-center justify-center overflow-hidden rounded-l-lg lg:hidden`}
              >
                <img
                  src={scheduleStyle?.image}
                  alt={scheduleStyle?.alt}
                  className="min-h-[120px] object-cover filter"
                />
              </div>
              <div className="flex flex-col col-span-9 gap-2 lg:col-span-7">
                <div className="flex flex-row gap-1">
                  <div className="flex flex-row gap-1">
                    <span
                      title={
                        schedule.recurring_schedule_id
                          ? 'Recurring reservation ' + dateFormatted
                          : dateFormatted
                      }
                      className="flex rounded-2 bg-success text-sm text-success-text font-bold px-1.5 py-0.5 gap-1"
                    >
                      <span>{getDistanceToNow(schedule.start)}</span>
                      {schedule.recurring_schedule_id ? (
                        <Repeat className="h-5 w-5" />
                      ) : null}
                    </span>
                    {parking ? (
                      <span
                        title={parking.resource?.name ?? 'Parking'}
                        className="items-center rounded-full border-2 border-tacticGrayBlue-600 px-[0.45rem] py-0.5 text-sm font-bold text-tacticGrayBlue-600"
                      >
                        P
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="flex flex-col gap-1 flex-1 text-tertiary">
                  <div>
                    <h3 className="text-xl font-normal">
                      {isRoom(prioritySlot.resource.type)
                        ? schedule.title
                        : prioritySlot.resource.name}
                    </h3>
                  </div>
                  <div>
                    <span className="text-primary font-normal">
                      {formatStartEndHours(schedule.start, schedule.end).join(
                        ' - '
                      )}
                    </span>
                  </div>
                  {isRoom(prioritySlot.resource.type) || true ? (
                    <div className="flex">
                      <span className="text-sm">
                        {prioritySlot.resource.name}
                      </span>
                      {schedule.description ? (
                        <Menu
                          shadow="md"
                          width={350}
                          position="bottom"
                          withinPortal
                        >
                          <Menu.Target>
                            <DocumentTextIcon className="ml-1 h-5 w-5 cursor-pointer" />
                          </Menu.Target>
                          <Menu.Dropdown>
                            <TextEditorContent
                              value={schedule.description}
                              className="border-none p-2"
                            />
                          </Menu.Dropdown>
                        </Menu>
                      ) : null}
                    </div>
                  ) : null}
                  <div>
                    <span className="text-md">
                      {resourceChainQuery.fullName}
                    </span>
                  </div>
                </div>
              </div>
              <div className="hidden lg:flex flex-col col-start-9 col-span-4 items-end justify-center gap-1">
                <div className="flex flex-row min-h-[28px]">
                  {isRoom(prioritySlot.resource.type) ? (
                    <Menu
                      width={300}
                      shadow="md"
                      position="bottom-start"
                      withinPortal
                    >
                      <Menu.Target>
                        <div className="mt-1 flex cursor-pointer items-center -space-x-1 overflow-hidden">
                          {uniqueMeetingRoomSlots.slice(0, 5).map((slot) => (
                            <UserPhotoCard
                              key={`array_${slot.id}`}
                              size={USER_PHOTO_SIZES.ROUNDED_6_6}
                              name={slot.user.name}
                              profile_photo_url={
                                slot.user.profile_photo_url
                                  ? slot.user.profile_photo_url
                                  : null
                              }
                              className="ring-2 ring-white"
                            />
                          ))}
                          {uniqueMeetingRoomSlots.length > 5 ? (
                            <div className="rounded-full bg-tacticBlue-100 p-1.5">
                              <PlusIcon className="h-4 w-4" />
                            </div>
                          ) : null}
                        </div>
                      </Menu.Target>
                      <Menu.Dropdown>
                        <ScrollArea
                          style={{ height: '200px' }}
                          offsetScrollbars
                        >
                          {uniqueMeetingRoomSlots.map((slot) => (
                            <Menu.Item
                              key={`dropdown_${slot.id}`}
                              className="p-2"
                            >
                              <div className="grid grid-cols-12 items-center">
                                <UserPhotoCard
                                  key={slot.id}
                                  size={USER_PHOTO_SIZES.ROUNDED_6_6}
                                  name={slot.user.name}
                                  profile_photo_url={
                                    slot.user.profile_photo_url
                                      ? slot.user.profile_photo_url
                                      : null
                                  }
                                  className="col-span-2 ring-2 ring-white"
                                />
                                <span className="col-span-8 truncate pl-1 text-tertiary">
                                  {slot.user.name}
                                </span>
                                <div className="col-span-2">
                                  {slot.presence_status ===
                                  PRESENCE_STATUS_REJECTED ? (
                                    <BanIcon className="h-5 w-5 text-red-500" />
                                  ) : null}
                                  {slot.presence_status ===
                                  PRESENCE_STATUS_PENDING ? (
                                    <QuestionMarkCircleIcon className="h-5 w-5 text-gray-600" />
                                  ) : null}
                                  {slot.presence_status ===
                                    PRESENCE_STATUS_ACCEPTED &&
                                  slot.presence_type ===
                                    PRESENCE_TYPE_IN_PERSON ? (
                                    <OfficeBuildingIcon className="h-5 w-5 text-green-500" />
                                  ) : null}
                                  {slot.presence_status ===
                                    PRESENCE_STATUS_ACCEPTED &&
                                  slot.presence_type ===
                                    PRESENCE_TYPE_REMOTE ? (
                                    <VideoCameraIcon className="h-5 w-5 text-green-500" />
                                  ) : null}
                                  {slot.presence_status ===
                                    PRESENCE_STATUS_ACCEPTED &&
                                  slot.presence_type === null ? (
                                    <CheckIcon className="h-5 w-5 text-green-500" />
                                  ) : null}
                                </div>
                              </div>
                            </Menu.Item>
                          ))}
                        </ScrollArea>
                      </Menu.Dropdown>
                    </Menu>
                  ) : null}
                </div>
                <div
                  className={`flex flex-col flex-1 gap-1 items-end ${remoteCheckInDisabled || reservationObject?.hasCheckedOut ? 'justify-end' : 'justify-center'}`}
                >
                  {reservationObject?.canRespondPresence && (
                    <div>
                      {isRoom(prioritySlot.resource.type) &&
                        userSlot &&
                        schedule.slots.length > 1 &&
                        !userMultipleResources && (
                          <ReservationCardRespondPresence
                            userSlot={userSlot}
                            schedule={schedule}
                            remoteEnabled={
                              reservationObject.canRespondPresenceRemote
                            }
                          />
                        )}
                    </div>
                  )}
                  <div>
                    {renderCheckIn.current ? (
                      <div>
                        {(isWorkspace(prioritySlot.resource.type) &&
                          !officeHasWorkspaceEnableCheckIn &&
                          !schedule.is_pending) ||
                        (isRoom(prioritySlot.resource.type) &&
                          !officeHasRoomEnableCheckIn &&
                          !schedule.is_pending) ? (
                          <Tooltip position="top">
                            Your office has disabled remote check-in. Please
                            check in via QR code or via NFC scan.
                          </Tooltip>
                        ) : null}

                        {(isWorkspace(prioritySlot.resource.type) &&
                          officeHasWorkspaceEnableCheckIn &&
                          !pendingApproval) ||
                        (isRoom(prioritySlot.resource.type) &&
                          officeHasRoomEnableCheckIn &&
                          !pendingApproval)
                          ? reservationObject?.slotId && (
                              <ReserveCheckInButton
                                loading={loading}
                                checkinResource={onCheckin}
                                checkoutResource={onCheckout}
                                isRBFlag
                                earliestCheckInTime={earliestCheckInTime}
                                pendingApproval={pendingApproval}
                                endTime={endTime}
                                scheduleId={scheduleId}
                                resourceId={resourceId}
                                {...reservationObject}
                              />
                            )
                          : null}

                        {pendingApproval ? (
                          <span className="inline-flex rounded-full py-1 px-2 text-xs bg-primary text-primary-text">
                            Pending Approval
                          </span>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row gap-2">
              {reservationObject?.canRespondCancel && (
                <Button
                  variant="transparent"
                  size="sm"
                  severity="critical"
                  iconSize="base"
                  layout="normal"
                  font="boldGrayBlue"
                  justifyCenter
                  className="inline-flex quicksand"
                  onClick={() => setConfirmDialog(true)}
                  mobileFull
                  iconLeft={TrashIcon}
                >
                  Remove
                </Button>
              )}
              {!!currentUserCanEdit && (
                <Button
                  variant="transparent"
                  size="sm"
                  iconSize="base"
                  layout="normal"
                  font="boldGrayBlue"
                  className="inline-flex quicksand"
                  onClick={() => {
                    setEditModal(true);
                  }}
                  mobileFull
                  iconLeft={Pencil}
                  justifyCenter
                >
                  Manage
                </Button>
              )}
              {resourceChainQuery.floor?.map_url ? (
                <Button
                  variant="transparent"
                  size="sm"
                  iconSize="base"
                  layout="normal"
                  font="boldGrayBlue"
                  justifyCenter
                  className="inline-flex quicksand"
                  onClick={() =>
                    goToMap(
                      router,
                      prioritySlot.resource.id,
                      prioritySlot.resource.office_id,
                      schedule.start,
                      resourceChainQuery.floor?.id
                    )
                  }
                  mobileFull
                  iconLeft={Map}
                >
                  Locate
                </Button>
              ) : null}
            </div>
            <div className="lg:hidden flex flex-col col-span-12 gap-1">
              <div className="flex flex-row justify-center">
                {isRoom(prioritySlot.resource.type) ? (
                  <Menu
                    width={300}
                    shadow="md"
                    position="bottom-start"
                    withinPortal
                  >
                    <Menu.Target>
                      <div className="mt-1 flex cursor-pointer items-center justify-center -space-x-1 overflow-hidden">
                        {uniqueMeetingRoomSlots.slice(0, 5).map((slot) => (
                          <UserPhotoCard
                            key={`array_${slot.id}`}
                            size={USER_PHOTO_SIZES.ROUNDED_6_6}
                            name={slot.user.name}
                            profile_photo_url={
                              slot.user.profile_photo_url
                                ? slot.user.profile_photo_url
                                : null
                            }
                            className="ring-2 ring-white"
                          />
                        ))}
                        {uniqueMeetingRoomSlots.length > 5 ? (
                          <div className="rounded-full bg-tacticBlue-100 p-1.5">
                            <PlusIcon className="h-4 w-4" />
                          </div>
                        ) : null}
                      </div>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <ScrollArea style={{ height: '200px' }} offsetScrollbars>
                        {uniqueMeetingRoomSlots.map((slot) => (
                          <Menu.Item
                            key={`dropdown_${slot.id}`}
                            className="p-2"
                          >
                            <div className="grid grid-cols-12 items-center">
                              <UserPhotoCard
                                key={slot.id}
                                size={USER_PHOTO_SIZES.ROUNDED_6_6}
                                name={slot.user.name}
                                profile_photo_url={
                                  slot.user.profile_photo_url
                                    ? slot.user.profile_photo_url
                                    : null
                                }
                                className="col-span-2 ring-2 ring-white"
                              />
                              <span className="col-span-8 truncate pl-1 text-tacticGrayBlue-600">
                                {slot.user.name}
                              </span>
                              <div className="col-span-2">
                                {slot.presence_status ===
                                PRESENCE_STATUS_REJECTED ? (
                                  <BanIcon className="h-5 w-5 text-red-500" />
                                ) : null}
                                {slot.presence_status ===
                                PRESENCE_STATUS_PENDING ? (
                                  <QuestionMarkCircleIcon className="h-5 w-5 text-gray-600" />
                                ) : null}
                                {slot.presence_status ===
                                  PRESENCE_STATUS_ACCEPTED &&
                                slot.presence_type ===
                                  PRESENCE_TYPE_IN_PERSON ? (
                                  <OfficeBuildingIcon className="h-5 w-5 text-green-500" />
                                ) : null}
                                {slot.presence_status ===
                                  PRESENCE_STATUS_ACCEPTED &&
                                slot.presence_type === PRESENCE_TYPE_REMOTE ? (
                                  <VideoCameraIcon className="h-5 w-5 text-green-500" />
                                ) : null}
                                {slot.presence_status ===
                                  PRESENCE_STATUS_ACCEPTED &&
                                slot.presence_type === null ? (
                                  <CheckIcon className="h-5 w-5 text-green-500" />
                                ) : null}
                              </div>
                            </div>
                          </Menu.Item>
                        ))}
                      </ScrollArea>
                    </Menu.Dropdown>
                  </Menu>
                ) : null}
              </div>
              <div className={`flex flex-col flex-1 gap-1`}>
                {reservationObject?.canRespondPresence && (
                  <div>
                    {isRoom(prioritySlot.resource.type) &&
                      userSlot &&
                      schedule.slots.length > 1 &&
                      !userMultipleResources && (
                        <ReservationCardRespondPresence
                          userSlot={userSlot}
                          schedule={schedule}
                          remoteEnabled={
                            reservationObject.canRespondPresenceRemote
                          }
                        />
                      )}
                  </div>
                )}
                <div>
                  {renderCheckIn.current ? (
                    <div>
                      {(isWorkspace(prioritySlot.resource.type) &&
                        !officeHasWorkspaceEnableCheckIn &&
                        !schedule.is_pending) ||
                      (isRoom(prioritySlot.resource.type) &&
                        !officeHasRoomEnableCheckIn &&
                        !schedule.is_pending) ? (
                        <Tooltip position="top">
                          Your office has disabled remote check-in. Please check
                          in via QR code or via NFC scan.
                        </Tooltip>
                      ) : null}

                      {(isWorkspace(prioritySlot.resource.type) &&
                        officeHasWorkspaceEnableCheckIn &&
                        !schedule.is_pending) ||
                      (isRoom(prioritySlot.resource.type) &&
                        officeHasRoomEnableCheckIn &&
                        !schedule.is_pending)
                        ? reservationObject?.slotId && (
                            <ReserveCheckInButton
                              loading={loading}
                              // forUserId={authenticatedUser.user?.id ?? ''}
                              checkinResource={onCheckin}
                              checkoutResource={onCheckout}
                              // schedule={schedule}
                              isRBFlag
                              earliestCheckInTime={earliestCheckInTime}
                              pendingApproval={pendingApproval}
                              endTime={endTime}
                              scheduleId={scheduleId}
                              resourceId={resourceId}
                              {...reservationObject}
                            />
                          )
                        : null}

                      {schedule.is_pending ? (
                        <span className="inline-flex rounded-full bg-tacticOrange-100 py-1 px-2 text-xs text-tacticprimary">
                          Pending Approval
                        </span>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
      <ReservationCancel
        schedules={[schedule]}
        setOpen={setConfirmDialog}
        open={confirmDialog}
      />
      <ReserveEditModal
        editModal={editModal}
        toggleModal={() => setEditModal(!editModal)}
        schedule={schedule}
        canRespondCancel={reservationObject?.canRespondCancel}
        respondCancel={() => setConfirmDialog(true)}
      />
    </div>
  );
};

export default RBReservationCardItem;
