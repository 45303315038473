import { Tooltip } from '@/components/basic';
import { DashboardQuestionnaireAlert } from '@/components/dashboard/new-dashboard/DashboardQuestionnaireAlert';
import RBReservationCardItem from '@/components/partial/Cards/ReservationCard/RBReservationCardItem';
import { getReserveCardKey } from '@/lib/utils-reserve';
import { IOfficesSchedulesIndexed } from '@gettactic/api';
import { useWindowSize } from '@gettactic/helpers/src/hooks/useWindowSize';
import { useSortedSchedules } from '@gettactic/hooks';
import { Accordion, Paper, ScrollArea } from '@mantine/core';
import { UseQueryResult } from '@tanstack/react-query';
import { format } from 'date-fns';
import Image from 'next/image';

type Props = {
  scheduleMeData: UseQueryResult<IOfficesSchedulesIndexed | null, unknown>;
};
export function DashboardReservations({ scheduleMeData }: Props) {
  const browserWindowSize = useWindowSize();
  const now = format(new Date(), 'yyyy-MM-dd');
  const all = useSortedSchedules(scheduleMeData.data?.elements);
  const pastSchedules = all.filter((s) => s.start < now);
  const upcomingSchedules = all.filter((s) => s.start >= now);

  const PastSchedules = () => (
    <>
      {pastSchedules.map((schedule) => (
        <RBReservationCardItem
          schedule={schedule}
          key={getReserveCardKey(schedule)}
          embedded={false}
        />
      ))}
    </>
  );
  const UpcomingSchedules = () => (
    <>
      {upcomingSchedules
        .filter((s) => s.start >= now)
        .map((schedule) => (
          <RBReservationCardItem
            schedule={schedule}
            key={getReserveCardKey(schedule)}
          />
        ))}
    </>
  );

  return (
    <Paper
      shadow="md"
      className="col-span-2 rounded-lg border border-gray-200 bg-white px-4 py-5 sm:px-6 md:col-span-1"
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <h3 className="font-medium text-gray-900 text-lg leading-6">
            Weekly Reservations
          </h3>
          <Tooltip>
            View upcoming reservations, meetings, reservations pending approval,
            and past reservations using the filters provided.
          </Tooltip>
        </div>
        <DashboardQuestionnaireAlert />
      </div>
      <div className="mt-4 h-full border-gray-200 border-t py-4">
        <div className="flex h-full flex-col">
          <ul className="h-full">
            {browserWindowSize?.height &&
            scheduleMeData.isSuccess &&
            scheduleMeData.data ? (
              <ScrollArea
                style={{
                  height: browserWindowSize.height - 64 - 203 - 16 - 175
                }}
                mx="auto"
                offsetScrollbars={
                  // Only add the automatic padding if screen is iPad or larger
                  !!(
                    // Only add the automatic padding if screen is iPad or larger
                    (browserWindowSize?.width && browserWindowSize.width > 768)
                  )
                }
                className="h-full"
              >
                {all.length === 0 ? (
                  <div className="mt-5 flex flex-col items-center justify-center text-center">
                    <div>
                      <Image
                        src="https://cdn.gettactic.com/website/images/reservations-no-items.svg"
                        width={313}
                        height={215}
                        alt="No reservations"
                      />
                    </div>
                    <p className="max-w-md text-center text-secondary text-xl">
                      You don’t have any confirmed reservations for this week.
                      Make sure to book a workspace before heading into the
                      office!
                    </p>
                  </div>
                ) : (
                  <div>
                    {/* If no upcoming just render PastSchedules */}
                    {pastSchedules.length && !upcomingSchedules.length ? (
                      <PastSchedules />
                    ) : null}
                    {/* If no past schedules just render UpcomingSchedules */}
                    {!pastSchedules.length && upcomingSchedules.length ? (
                      <UpcomingSchedules />
                    ) : null}
                    {/* We have both so use an accordion for grouping them */}
                    {pastSchedules.length && upcomingSchedules.length ? (
                      <Accordion
                        defaultValue={
                          upcomingSchedules.length ? '' : 'past-items'
                        }
                      >
                        {pastSchedules.length > 0 ? (
                          <Accordion.Item value="past-items">
                            <Accordion.Control className="-mx-4">
                              Past Reservations ({pastSchedules.length})
                            </Accordion.Control>
                            <Accordion.Panel>
                              <div className="-mx-4">
                                <PastSchedules />
                              </div>
                            </Accordion.Panel>
                            <div className="my-5 border-gray-200 border-t">
                              <h4 className="my-5">Upcoming</h4>
                              <UpcomingSchedules />
                            </div>
                          </Accordion.Item>
                        ) : null}
                      </Accordion>
                    ) : null}
                  </div>
                )}
              </ScrollArea>
            ) : null}
          </ul>
        </div>
      </div>
    </Paper>
  );
}
