import { logError } from '../errors';
import { LAST_FLOOR_STORAGE_KEY } from './defines';

export function setStorageFloor(floor: string | null | undefined) {
  try {
    localStorage.setItem(LAST_FLOOR_STORAGE_KEY, floor ?? '');
  } catch (e) {
    logError('Storage', e);
  }
}
