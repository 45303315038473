import LoadingSpinner from '@/components/util/LoadingSpinner';
import { api } from '@/lib/api/api';
import { classNames } from '@/lib/classNames';
import { allowShowDashboardStats } from '@/lib/featureFlags';
import { parseApiDate } from '@/lib/utils';
import {
  IAttendanceRequestType,
  IOfficeUsageFormatted,
  IWorkingLocation,
  ScheduleParamsAll
} from '@gettactic/api';
import { useAuthenticated } from '@gettactic/hooks';
import { RingProgress } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { format, startOfWeek } from 'date-fns';
import { motion } from 'framer-motion';
import React from 'react';

type Props = {
  flipped: boolean;
  setFlipped: React.Dispatch<React.SetStateAction<boolean>>;
  fullLocation?: IWorkingLocation | IAttendanceRequestType | null;
  day: string;
};
const variants = {
  flipped: {
    rotateX: 180,
    scale: 1.0,
    transition: { duration: 0.35 }
  },
  default: { opacity: 1, x: 0 }
};

export function DayPill({ day, flipped, setFlipped, fullLocation }: Props) {
  const {
    userContext: { currentOffice, authenticatedUser }
  } = useAuthenticated();
  const allowStats = allowShowDashboardStats(authenticatedUser);
  const date = parseApiDate(day);
  const officeId =
    fullLocation && 'office' in fullLocation
      ? (fullLocation?.office?.id ??
        currentOffice.id ??
        authenticatedUser.officeId)
      : (currentOffice.id ?? authenticatedUser.officeId);
  const statsOffice = (authenticatedUser.offices?.offices ?? []).find(
    (x) => x.id === officeId
  );
  const firstMondayBefore = startOfWeek(date, { weekStartsOn: 1 });
  const params: ScheduleParamsAll = {
    date: format(firstMondayBefore, 'yyyy-MM-dd'),
    office: statsOffice?.id ? [statsOffice?.id] : []
  };
  const usageLocation = useQuery(
    ['schedules', 'usageOccupied', params],
    async () => {
      const res = await api.client.schedules.allUsage('occupied', params);
      return res.result ? api.client.schedules.formatUsages(res.result) : null;
    },
    {
      keepPreviousData: true,
      enabled: statsOffice && !!statsOffice.id && flipped
    }
  );

  const locationUsageData = usageLocation.data?.usages.find(
    (x) => x.date === day
  );

  const today = format(new Date(), 'yyyy-MM-dd');

  return (
    <motion.div animate={flipped ? 'flipped' : 'default'} variants={variants}>
      <div
        className={classNames(
          flipped ? 'h-[105px]' : 'h-[45px]',
          day === today
            ? 'bg-primary text-primary-text'
            : day > today
              ? 'bg-secondary text-secondary-text'
              : 'bg-past_event text-past_event-text',
          'rounded-xl px-4 py-2',
          allowStats ? 'cursor-pointer' : ''
        )}
        onClick={() =>
          allowStats ? setFlipped((flipped) => !flipped) : undefined
        }
      >
        {!flipped ? (
          <div className="flex justify-between">
            <span className="text-2xl">{format(date, 'dd')}</span>
            <span className="text-xl">{format(date, 'EEE')}</span>
          </div>
        ) : (
          <div className="mirror-text relative flex flex-col">
            <span
              className={classNames(
                'absolute -right-2 -top-1 space-x-2 font-bold'
              )}
            >
              <span className="">{format(date, 'EEE')}</span>
              <span className="">{format(date, 'dd')}</span>
            </span>
            <span
              title={statsOffice?.name}
              className="w-24 truncate text-sm font-bold leading-4"
            >
              {statsOffice?.name}
            </span>
            {locationUsageData ? (
              <>
                <span>
                  {locationUsageData.free}/{locationUsageData.total} Desks
                  Available
                </span>
                <div className="flex items-center">
                  <RingProgress
                    title={`${((locationUsageData.accepted / locationUsageData.total) * 100).toFixed(0)}% in office`}
                    size={20}
                    roundCaps
                    thickness={3}
                    sections={[
                      {
                        value: locationUsageData.total - locationUsageData.free,
                        color: 'blue'
                      }
                    ]}
                  />
                  <span className="pl-1">
                    {locationUsageData.accepted} in office
                  </span>
                </div>
              </>
            ) : (
              <LoadingSpinner />
            )}
          </div>
        )}
      </div>
    </motion.div>
  );
}
