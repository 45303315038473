import { OOODateMultiplePicker } from '@/components/attendance/employee/form/OOODateMultiplePicker';
import { OOODatePicker } from '@/components/attendance/employee/form/OOODatePicker';
import { OOOOverlapWarning } from '@/components/attendance/employee/form/OOOOverlapWarning';
import OOOSelector from '@/components/attendance/employee/form/OOOSelector';
import { NewButton } from '@/components/basic';
import { Dialog, DialogContent, DialogHeader } from '@/components/ui/dialog';
import { useAllApplicablePolicies } from '@/hooks/useApplicablePolicies';
import { api } from '@/lib/api/api';
import { classNames } from '@/lib/classNames';
import { parseApiDate } from '@/lib/utils';
import { LeavePolicyType } from '@gettactic/api';
import { useWindowSize } from '@gettactic/helpers/src/hooks/useWindowSize';
import { useAuthenticated } from '@gettactic/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

type Props = {
  request: { date: string; type: 'ooo' | 'remote' } | null;
  onClose: () => void;
};

type Form = {
  type: string;
  reason: string;
  dates: {
    start: Date;
    end: Date | null;
  };
  multipleDates: Date[];
};

function getDefaults(
  request: { date: string; type: 'ooo' | 'remote' } | null,
  optionsForPolicyType: { value: string }[]
) {
  const isRemote = request?.type !== 'ooo';
  return {
    type: !isRemote
      ? optionsForPolicyType.length > 0
        ? optionsForPolicyType[0].value
        : ''
      : 'remote',
    reason: '',
    dates: {
      start: request?.date ? parseApiDate(request?.date) : new Date(),
      end: request?.date ? parseApiDate(request?.date) : new Date()
    },
    multipleDates: isRemote
      ? [request?.date ? parseApiDate(request?.date) : new Date()]
      : []
  };
}

export function OOORequest({ request, onClose }: Props) {
  const {
    userContext: { deviceTz }
  } = useAuthenticated();
  const queryClient = useQueryClient();
  const browserWindowSize = useWindowSize();
  const browserHeight = browserWindowSize.height;
  const isRemote = request?.type === 'remote';
  const {
    handleSubmit,
    control,
    register,
    reset,
    watch,
    formState: { errors }
  } = useForm<Form>({
    defaultValues: getDefaults(request, [])
  });

  const maxLength = 500;

  const { query: policiesQuery, invalidate } = useAllApplicablePolicies({
    tz: deviceTz
  });

  const dates = watch('dates');

  const optionsForPolicyType = useMemo(() => {
    return policiesQuery.data
      ? policiesQuery.data
          .filter(
            (x) =>
              x.policy.policy_type !== 'remote' &&
              x.policy.policy_type !== 'office'
          )
          .map((x) => ({
            label: x.policy.title ? x.policy.title : x.policy.policy_type,
            value: x.policy.policy_type,
            available: x.available,
            is_approvable: x.policy.is_approvable
          }))
      : [];
  }, [policiesQuery.data]);

  useEffect(() => {
    reset(getDefaults(request, optionsForPolicyType));
  }, [request, optionsForPolicyType]);

  const onSubmit = async (data: Form) => {
    try {
      if (!isRemote) {
        await api.client.leave.createRequest({
          policy_type: data.type as LeavePolicyType,
          reason: data.reason,
          starts: format(data.dates.start, 'yyyy-MM-dd') + 'T00:00:00.000Z',
          ends: data.dates.end
            ? format(data.dates.end, 'yyyy-MM-dd') + 'T23:59:59.000Z'
            : format(data.dates.start, 'yyyy-MM-dd') + 'T23:59:59.000Z',
          time_zone_id: deviceTz
        });
        const isApprovable =
          optionsForPolicyType.find((x) => x.value === data.type)
            ?.is_approvable ?? false;
        toast.success(
          !isApprovable
            ? 'Your request was submitted'
            : 'Your organization’s policies require your request to be approved. Once approved it will display here.'
        );
      } else {
        const all = data.multipleDates.map(async (date) => {
          await api.client.leave.createRequest({
            policy_type: data.type as LeavePolicyType,
            reason: data.reason,
            starts: format(date, 'yyyy-MM-dd') + 'T00:00:00.000Z',
            ends: format(date, 'yyyy-MM-dd') + 'T23:59:59.000Z',
            time_zone_id: deviceTz
          });
        });
        await Promise.all(all);
        toast.success(
          'Your organization’s Remote Work policy require your request to be approved.'
        );
      }
      onClose();
    } catch (e) {
      toast.error('Something went wrong');
    }
    await invalidate();
    await queryClient.invalidateQueries(['schedules']);
    await queryClient.invalidateQueries(['attendanceRequests']);
    await queryClient.invalidateQueries(['leavePolicies']);
  };

  return (
    <Dialog open={!!request} onOpenChange={onClose}>
      <DialogContent className="max-w-[28rem]">
        <DialogHeader>
          <div
            className={classNames(
              isRemote ? 'bg-orange-100' : 'bg-green-100',
              'margin-auto absolute top-0 left-0 m-0 flex h-20  w-full items-center justify-between rounded-md  py-10 pl-5 pr-10 text-secondary'
            )}
          >
            {isRemote ? (
              <span className="text-2xl">Remote Day Request</span>
            ) : (
              <span className="text-3xl">Time-Off Request</span>
            )}
            <img
              src={
                !isRemote
                  ? 'https://cdn.gettactic.com/website/images/time-off-request-icon.svg'
                  : 'https://cdn.gettactic.com/website/images/remote-graphic 1.svg'
              }
              alt="Time off request image"
            />
          </div>
        </DialogHeader>
        <div className="max-w-[28rem] px-4">
          <div
            style={{
              maxHeight: browserHeight ? `${browserHeight - 200}px` : '28rem'
            }}
            className="pr-3 pl-1"
          >
            <form className="mt-14" onSubmit={handleSubmit(onSubmit)}>
              <div className="mt-4">
                {isRemote ? (
                  <Controller
                    name="type"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value } }) => (
                      <input type="hidden" value={value} />
                    )}
                  />
                ) : (
                  <Controller
                    name="type"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <OOOSelector
                        options={optionsForPolicyType}
                        value={value}
                        setValue={onChange}
                      />
                    )}
                  />
                )}
              </div>
              <div className="mt-4">
                {isRemote ? (
                  <>
                    <Controller
                      name="multipleDates"
                      control={control}
                      rules={{ required: true, min: 1 }}
                      render={({ field: { onChange, value } }) => (
                        <OOODateMultiplePicker
                          onChange={(dates) => onChange(dates)}
                          dates={value}
                        />
                      )}
                    />
                    {errors.multipleDates?.type === 'required' ? (
                      <p className="px-1 text-red-700">
                        You must choose at least one date to make this request
                      </p>
                    ) : null}
                  </>
                ) : (
                  <Controller
                    name="dates"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <OOODatePicker
                        onChange={(start, end) => onChange({ start, end })}
                        start={value.start}
                        end={value.end}
                      />
                    )}
                  />
                )}
              </div>

              {!isRemote ? (
                <div className="mt-4">
                  <OOOOverlapWarning dates={dates} />
                </div>
              ) : null}

              <div className="mt-4">
                <textarea
                  {...register('reason', { required: false, maxLength })}
                  rows={4}
                  placeholder="Reason for request (optional)..."
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
                  maxLength={maxLength}
                />
                {errors.reason?.type === 'maxLength' ? (
                  <p className="px-1 text-red-700">
                    Please use a max of {maxLength} chars
                  </p>
                ) : null}
              </div>
              <div className="mt-5 flex justify-center text-center">
                <NewButton.Component type="submit" className="h-10 px-20">
                  Submit Request
                </NewButton.Component>
              </div>
            </form>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
