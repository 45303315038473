import { DatePicker } from '@/components/basic';
import { useI18n } from '@/hooks/useI18n';
import { useAuthenticated } from '@gettactic/hooks';
import { CalendarIcon } from '@heroicons/react/outline';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { format, parse, startOfWeek } from 'date-fns';
import React, { PropsWithChildren, useRef } from 'react';
import ReactDatePicker from 'react-datepicker';

type Props = {
  setDate: (x: string) => void;
  date: string;
  className?: string;
};
export function DatePickerDashboard({
  children,
  setDate,
  date,
  className = 'inline-flex items-center rounded-full border border-transparent bg-white p-2 text-gray-800 drop-shadow-[0_1px_3px_rgba(0,0,0,0.25)] hover:bg-primary hover:text-white focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2'
}: PropsWithChildren<Props>) {
  const today = new Date();
  const parsedDate = parse(date, 'yyyy-MM-dd', new Date());
  const firstMondayBefore = startOfWeek(parsedDate, { weekStartsOn: 1 });
  const calRef = useRef<ReactDatePicker | null>(null);
  const {
    userContext: { authenticatedUser }
  } = useAuthenticated();
  const { templates } = useI18n(authenticatedUser);

  return (
    <div title={date}>
      <DatePicker
        ref={calRef}
        onChange={(date) => {
          if (date) {
            setDate(format(date, 'yyyy-MM-dd'));
          }
        }}
        selected={firstMondayBefore}
        dateFormat={templates.displayDate}
        customInput={
          <button
            title={format(parsedDate, templates.displayMonthDayYearWordy)}
            type="button"
            className={className}
          >
            <CalendarIcon className="h-6 w-6" />
            {children}
          </button>
        }
        className="input w-full rounded-md"
        calendarClassName="tailwind-datepicker whole-week"
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled
        }) => {
          return (
            <div className="w-full px-4">
              <div className="flex w-full ">
                <button
                  className="min-w-30 border-1 w-full rounded-md border border-primary py-1 px-2 text-lg font-semibold text-primary"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setDate(format(today, 'yyyy-MM-dd'));
                    if (calRef.current) {
                      calRef.current.setOpen(false);
                    }
                  }}
                >
                  Today
                </button>
              </div>
              <div className="my-4 flex w-full justify-between">
                <button
                  disabled={prevMonthButtonDisabled}
                  onClick={decreaseMonth}
                  type="button"
                  className=""
                  aria-label="Previous Month"
                >
                  <span className="">
                    <ChevronLeftIcon className="h-5 w-5" />
                  </span>
                </button>
                <span className="text-lg text-gray-800">
                  {format(date, templates.displayDate)}
                </span>
                <button
                  disabled={nextMonthButtonDisabled}
                  onClick={increaseMonth}
                  type="button"
                  className=""
                  aria-label="Next Month"
                >
                  <span className="">
                    <ChevronRightIcon className="h-5 w-5" />
                  </span>
                </button>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
}
