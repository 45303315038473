import { DatePicker } from '@/components/basic';
import { useI18n } from '@/hooks/useI18n';
import { classNames } from '@/lib/classNames';
import { useAuthenticated } from '@gettactic/hooks';
import { CalendarIcon } from '@heroicons/react/outline';
import { format } from 'date-fns';
import React from 'react';

type Props = {
  dates: Date[];
  onChange: (dates: Date[]) => void;
};
export function OOODateMultiplePicker({ dates, onChange }: Props) {
  const {
    userContext: { authenticatedUser }
  } = useAuthenticated();
  const { templates } = useI18n(authenticatedUser);

  const value =
    dates.length > 1
      ? `Multiple Dates (${dates.length} days)`
      : dates.length === 0
        ? 'Choose date(s)'
        : format(dates[0], templates.displayDate);
  return (
    <div className="inline-multiple">
      <DatePicker
        highlightDates={dates}
        onChange={(changeDate) => {
          if (changeDate instanceof Date) {
            const w: Date[] = [...dates];
            const idx = w.findIndex(
              (x) =>
                format(x, 'yyyy-MM-dd') === format(changeDate, 'yyyy-MM-dd')
            );
            if (idx === -1) {
              onChange([...w, changeDate]);
            } else {
              w.splice(idx, 1);
              onChange(w);
            }
          }
        }}
        shouldCloseOnSelect={false}
        dateFormat={templates.displayDate}
        customInput={
          <span className="inline-flex items-center rounded-full border border-gray-300 bg-white p-2 text-gray-800 ">
            <CalendarIcon className="h-6 w-6" />
            <span className="ml-4">{value}</span>
            <span
              className={classNames(
                'ml-2 rounded-full bg-gray-100 px-2.5  py-0.5 font-medium text-gray-800 '
              )}
            >
              {dates.length} {dates.length === 1 ? 'day' : 'days'}
            </span>
          </span>
        }
        className="input w-full rounded-md"
        calendarClassName="tailwind-datepicker"
      />
    </div>
  );
}
