import { Button, Modal, Responsive } from '@/components/basic';
import {
  USER_PHOTO_SIZES,
  UserPhotoCard
} from '@/components/partial/Cards/UserPhotoCard';
import TacticPhotoUpload, {
  PhotoUploadType
} from '@/components/upload/TacticPhotoUpload';
import { LoadingSpinner } from '@/components/util/LoadingSpinner';
import TimeZonePicker from '@/components/util/TimeZoneSelect';
import useAlreadySeen from '@/hooks/useAlreadySeen';
import { api } from '@/lib/api/api';
import { classNames } from '@/lib/classNames';
import { dateFormatOptions } from '@/lib/validation/profile';
import { AuthenticatedUser } from '@gettactic/api';
import { useAuthenticated } from '@gettactic/hooks';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Image from 'next/image';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

const profileIllustration =
  'https://cdn.gettactic.com/website/images/profile_illustration.svg';

export const NewUserWelcomeModal = ({
  authenticatedUser
}: { authenticatedUser: AuthenticatedUser }): JSX.Element => {
  const queryClient = useQueryClient();
  const alreadySeen = useAlreadySeen('NewUserWelcomeModal');
  const { setUserContext } = useAuthenticated();
  const userData = authenticatedUser,
    officeData = authenticatedUser.offices;

  const [isWelcomeModalOpen, setNewUserWelcomeModal] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { control, handleSubmit } = useForm();
  const photoUrl = userData.user?.profile_photo_url ?? null;

  const needsCompleteProfile = [
    // has photo?
    photoUrl !== null
  ].some((x) => !x);

  useEffect(() => {
    if (!needsCompleteProfile || alreadySeen) {
      return;
    }
    setNewUserWelcomeModal(true);
  }, [needsCompleteProfile, alreadySeen]);

  function closeNewUserWelcomeModal(): void {
    setNewUserWelcomeModal(false);
  }

  const submitMutation = useMutation(
    async (request: any) => {
      setLoading(true);
      if (!authenticatedUser.user) {
        throw new Error('User not found');
      }
      const res = await api.client.organizations.updateMe(request);
      return { res };
    },
    {
      onSuccess: (result, variables) => {
        setLoading(false);
        queryClient.invalidateQueries(['organizations.me']);
        toast.success('Changes saved');
        closeNewUserWelcomeModal();
        if (variables.defaultOfficeId) {
          setUserContext({
            type: 'updateCurrentOffice',
            payload: variables.defaultOfficeId
          });
        }
      }
    }
  );

  const onProfileSubmit = async (data: any) => {
    const defaultOfficeId = data.default_office_id?.value
      ? data.default_office_id.value
      : officeData !== null && officeData.officeId !== null
        ? officeData.officeId
        : null;
    const request = {
      name: data.name,
      phone: data.phone,
      location: data.location,
      about: data.about,
      time_zone: data.time_zone,
      title: data.title,
      date_format: data.date_format.value,
      profile_photo_url: photoUrl,
      default_office_id: defaultOfficeId,
      default_presence_type:
        authenticatedUser.user?.organization_user.default_presence_type ??
        'in_person' // TODO: Add default presence drop down to this modal
    };
    submitMutation.mutate(request);
  };

  return (
    <Modal opened={isWelcomeModalOpen} onClose={closeNewUserWelcomeModal}>
      <form onSubmit={handleSubmit(onProfileSubmit)}>
        <div>
          <div className="grid grid-cols-1 place-items-center gap-x-8 sm:grid-cols-2">
            <div className="hidden px-4 py-20 text-center lg:block">
              <h2 className="mb-12 text-4xl font-bold leading-normal text-secondary">
                Let&apos;s complete <br /> your profile!
              </h2>

              <div>
                <Image
                  src={photoUrl ?? profileIllustration}
                  width={286}
                  height={206}
                  alt="Profile upload illustration"
                />
              </div>
            </div>
            <div className="grid w-full grid-cols-12 gap-4">
              <div className="col-span-12">
                <div className="relative flex justify-center overflow-hidden rounded-full">
                  <TacticPhotoUpload
                    aspect={1}
                    resizeImage={{ w: 300, h: 300 }}
                    type={PhotoUploadType.UserProfile}
                  >
                    {({ loading, isDragActive }) => (
                      <>
                        <div className="mx-auto text-center">
                          <UserPhotoCard
                            className="hidden sm:block"
                            size={USER_PHOTO_SIZES.ROUNDED_40_40}
                            profile_photo_url={photoUrl}
                            name={userData.user?.name}
                          />
                          <UserPhotoCard
                            className="block sm:hidden"
                            size={USER_PHOTO_SIZES.ROUNDED_28_28}
                            profile_photo_url={photoUrl}
                            name={userData.user?.name}
                          />
                        </div>

                        {loading ? (
                          <div className="absolute inset-0 flex h-full w-full items-center justify-center bg-black bg-opacity-75 text-center font-medium text-white opacity-80">
                            <LoadingSpinner />
                          </div>
                        ) : (
                          <div>
                            <div
                              className={classNames(
                                isDragActive && ' opacity-100 ',
                                !isDragActive && ' opacity-0 ',
                                'absolute inset-0 hidden h-full w-full items-center justify-center bg-black  bg-opacity-75 font-medium text-white focus-within:opacity-100  hover:opacity-100 sm:flex'
                              )}
                            >
                              <span>
                                {isDragActive ? 'Drop here' : 'Change'}
                              </span>
                              <span className="sr-only"> user photo</span>
                              <div className="absolute inset-0 h-full w-full cursor-pointer rounded-md border-gray-300 opacity-0" />
                            </div>
                            <div className="text-trueGray-500 block font-medium sm:hidden">
                              Upload photo
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </TacticPhotoUpload>
                </div>
              </div>

              <Controller
                control={control}
                name="name"
                defaultValue={userData.user?.name}
                render={({ field }) => (
                  <Responsive.Label
                    filled={field.value.length > 0}
                    className="col-span-12"
                    text="Name"
                    htmlFor="name"
                  >
                    <Responsive.Input
                      type="text"
                      id="name"
                      autoComplete="name"
                      {...field}
                    />
                  </Responsive.Label>
                )}
              />
              <Controller
                control={control}
                name="title"
                defaultValue={userData.user?.organization_user?.title ?? ''}
                render={({ field }) => (
                  <Responsive.Label
                    filled={field.value.length > 0}
                    className="col-span-12"
                    text="Title"
                    htmlFor="title"
                  >
                    <Responsive.Input type="text" id="title" {...field} />
                  </Responsive.Label>
                )}
              />
              <Controller
                name="date_format"
                control={control}
                defaultValue={dateFormatOptions.find(
                  (x) =>
                    x.value === userData.user?.organization_user?.date_format
                )}
                render={({ field }) => (
                  <Responsive.Label
                    className="col-span-12 w-full"
                    text="Date Format"
                    filled={true}
                  >
                    <Responsive.Select options={dateFormatOptions} {...field} />
                  </Responsive.Label>
                )}
              />

              {officeData && officeData.offices.length > 0 && (
                <Controller
                  name="default_office_id"
                  control={control}
                  defaultValue={
                    officeData.officeId !== null &&
                    officeData.byId[officeData.officeId]
                      ? {
                          label: officeData.byId[officeData.officeId].name,
                          value: officeData.byId[officeData.officeId].id
                        }
                      : null
                  }
                  render={({ field }) => (
                    <Responsive.Label
                      className="col-span-12 w-full"
                      filled={!!field.value}
                      text="Default Office"
                      id="label-default-office"
                    >
                      <Responsive.Select
                        options={officeData.offices.map((office) => {
                          return {
                            label: office.name,
                            value: office.id
                          };
                        })}
                        aria-labelledby="label-default-office"
                        {...field}
                      />
                    </Responsive.Label>
                  )}
                />
              )}

              <Controller
                name="time_zone"
                control={control}
                rules={{ required: true }}
                defaultValue={authenticatedUser.user?.time_zone}
                render={({ field }) => (
                  <Responsive.Label
                    filled={!!field.value}
                    className="col-span-12"
                    text="Time Zone"
                    id="label-time-zone"
                  >
                    <TimeZonePicker
                      {...{
                        ...field,
                        'aria-labelledby': 'label-time-zone'
                      }}
                    />
                  </Responsive.Label>
                )}
              />

              <div className="col-span-12 sm:mt-2">
                <Button
                  className="w-full rounded-3xl sm:rounded-md"
                  layout="squared"
                  variant="primary"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? (
                    <LoadingSpinner color="text-white" />
                  ) : (
                    <span>Save and Explore</span>
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default NewUserWelcomeModal;
