import { IResource } from '@gettactic/api';
import { NextRouter } from 'next/router';

/**
 * Filter for workspaces that belong to an organization/office but have not been assigned to a map
 */
export function unassignedWorkspacesFilter(
  floorSpecificResources: IResource[],
  mapResourceIds: string[]
) {
  return floorSpecificResources?.filter(
    (x) => !mapResourceIds.includes(x.id) && x.type != 'area'
  );
}
/**
 * Filter for map node ids that do not belong to an organization/office. This can happen when a map is uploaded and map points have not been assigned.
 */
export function nonOfficeMatchingResourcesFilter(
  mapResourceIds: string[],
  resources: IResource[]
) {
  return mapResourceIds.filter((x) => !resources?.find((r) => r.id === x));
}

/**
 * Filter for map node ids that DO belong to an organization/office. These can be edited, even if they haven't been assigned to a floor or map.
 * Update: Changing filter by map so we keep the original selected order
 */
export function officeMatchingResourcesFilter(
  mapResourceIds: string[],
  resources: IResource[]
) {
  return mapResourceIds
    .map((x) => resources.find((r) => r.id === x))
    .filter((x) => !!x) as IResource[];
}

/**
 * Filter for workspaces that belong to an organization/office and ARE assigned to the map, but not to the correct floor
 */
export function nonFloorMatchingResourcesFilter(
  mapResourceIds: string[],
  resources: IResource[],
  floorSpecificResources: IResource[]
) {
  return mapResourceIds.filter(
    (x) =>
      resources?.find((r) => r.id === x) &&
      !floorSpecificResources?.find((r) => r.id === x)
  );
}

/**
 * Filter for workspaces in an office that have a corresponding NFC tag
 */
export function hasNFCFilter(resources: IResource[]) {
  return resources?.filter((r) => r.has_tags === true);
}

/*
 * Type guard for custom events
 */
export function isCustomEvent(e: Event | undefined): e is CustomEvent {
  return !!e && 'detail' in e;
}

/**
 * Determine if an element is attached to the DOM
 * Panzoom requires this so events work properly
 */
export function isAttachedToDOM(
  elem: HTMLElement | SVGElement | Document | Element
) {
  const doc = elem.ownerDocument;
  const parent = elem.parentNode;
  return (
    doc &&
    parent &&
    doc.nodeType === 9 &&
    parent.nodeType === 1 &&
    doc.documentElement.contains(parent)
  );
}

export function fillDynamicLabels(
  map: SVGElement,
  resources: IResource[] | undefined
) {
  if (!resources || !map) {
    return;
  }
  map.querySelectorAll('[id^="tactic_id_label_"]').forEach((textNode) => {
    const fullId = textNode.getAttribute('id') ?? '-';
    const id = fullId.replace('tactic_id_label_', '');
    const res = resources.find((x) => x.id === id);
    if (res?.label?.length && textNode.firstElementChild) {
      textNode.firstElementChild.innerHTML = res.label;
    }
  });
}

/**
 * Redirect user to map page at the given resource
 * @param router router to use
 * @param resourceId
 * @param officeId
 * @param date
 * @param floorId
 */
export const goToMap = (
  router: NextRouter,
  resourceId: string,
  officeId: string,
  date: string,
  floorId: string | null = null
) => {
  const dateStr = date.split('T')[0];
  const params = new URLSearchParams();
  params.set('date', dateStr);
  params.set('resource', resourceId);
  params.set('office', officeId);
  if (floorId) {
    params.set('floor', floorId);
  }

  router.push(`/map#${params.toString()}`);
};
