import NewDashboard from '@/components/dashboard/layouts/NewDashboard';
import OldDashboard from '@/components/dashboard/layouts/OldDashboard';
import { getLayout } from '@/components/layouts/AppLayout/AppLayout';
import MetaHead from '@/components/util/MetaHead';
import {
  isHpe,
  isOldDashboardDefault,
  isWorkingLocationsEnabled
} from '@/lib/featureFlags';
import { useAuthenticated } from '@gettactic/hooks';
import { useRouter } from 'next/router';

function Dashboard(): JSX.Element {
  const {
    userContext: { authenticatedUser }
  } = useAuthenticated();
  const router = useRouter();

  // There are 4 possible states:
  // 1. The user should only be shown the new dashboard. Either new customer or migrated.
  // 2. The user is NOT migrated and should be shown the old dashboard.
  // 3. The user is NOT migrated BUT there are specific users that have access to the new dashboard.
  // 4. The user is a Tactic employee and should be shown the user's default when impersonating but should have the option to switch.
  const newDashboardAccess = isWorkingLocationsEnabled(authenticatedUser);
  const accessToNewDefaultOld = isOldDashboardDefault(authenticatedUser);

  const isPrivacyEnabled = isHpe(authenticatedUser);

  return (
    <>
      <MetaHead
        title={`Dashboard | ${authenticatedUser.organization?.name ? authenticatedUser.organization?.name : 'Tactic'}`}
        robots="none"
        pageURL={`https://${authenticatedUser.organization?.slug}.gettactic.com/dashboard`}
      />
      {router.query.old && !isPrivacyEnabled ? <OldDashboard /> : null}
      {router.query.new ? (
        <div className="h-full bg-white text-tertiary">
          <NewDashboard />
        </div>
      ) : null}
      {accessToNewDefaultOld ? <OldDashboard /> : null}
      {!newDashboardAccess ? <OldDashboard /> : null}
      {newDashboardAccess ? (
        <div className="h-full bg-white">
          <NewDashboard />
        </div>
      ) : null}
    </>
  );
}

Dashboard.getLayout = getLayout;
Dashboard.extraProps = { hideOfficeMenu: true };

export default Dashboard;
